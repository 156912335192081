.image-default {
  display: inline-block;
  -webkit-box-align: center;
  align-items: center;
}
.image-default .image-box {
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  justify-content: center;
  -webkit-box-pack: center;
}
.image-default .image-box img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}
.image-default .image-box .image-box-black {
  background: #000;
  opacity: .5;
  display: none;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border-radius: 4px;
}
.image-default .image-box .repalce-btn {
  width: 40px;
  height: 30px;
  font-size: 12px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: none;
  z-index: 2;
  background: #fff;
  border-radius: 4px;
  font-weight: 600;
  color: #595961;
  line-height: 30px;
}
.image-default .image-box:hover > .repalce-btn {
  display: block;
}
.image-default .image-box:hover > .image-box-black {
  display: block;
}
.image-multiple-body {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.image-multiple-body .multiple-image-item {
  position: relative;
  cursor: pointer;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  padding: 2px;
  margin-right: 10px;
}
.image-multiple-body .multiple-image-item .image-item-box img {
  border-radius: 4px;
  object-fit: cover;
}
.image-multiple-body .multiple-image-item .image-delete {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #ffffff;
  color: #c5c5c5;
}
.image-multiple-body .multiple-image-item:hover {
  border: 1px solid #40a9ff;
}
.image-multiple-body .multiple-image-item:hover .image-delete {
  color: #545353;
}
.image-multiple-body .image-dotted {
  display: inline-block;
  -webkit-box-align: center;
  align-items: center;
}
.image-multiple-body .image-dotted .image-box {
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  border: 1px dashed #e2e2e2;
  justify-content: center;
  -webkit-box-pack: center;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
}
.image-multiple-body .image-dotted .image-box .icon-puls {
  font-size: 32px;
  color: #e2e2e2;
}
.image-multiple-body .image-dotted .image-box:hover {
  border: 1px dashed #40a9ff;
  color: #40a9ff;
  transition: all 0.5s;
}
.image-multiple-body .image-dotted .image-box:hover .icon-puls {
  color: #40a9ff;
}
.image-group {
  height: 550px;
  padding: 24px;
  border-right: 1px solid #e6e6e6;
}
.image-list {
  padding: 0 20px;
}
.image-list .action-top {
  margin: 16px 8px;
}
.image-list .action-top .upload-action {
  text-align: right;
}
.image-list .action-top .upload-action .upload-desc {
  margin-right: 10px;
  color: #999999;
  font-size: 12px;
}
.image-list .image-list-body .image-list-box {
  height: 420px;
}
.image-list .image-list-body .image-list-box .image-item {
  cursor: pointer;
  position: relative;
  border-radius: 2px;
  padding: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  text-align: center;
  margin: 8px;
}
.image-list .image-list-body .image-list-box .image-item .image-cover-box .image-cover {
  width: 95px;
  height: 95px;
  object-fit: cover;
  margin: 0 auto;
}
.image-list .image-list-body .image-list-box .image-item .image-file-name {
  font-size: 12px;
  margin: 0;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.image-list .image-list-body .image-list-box .image-item .active-mask {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.41);
  text-align: center;
  border-radius: 2px;
}
.image-list .image-list-body .image-list-box .image-item .active-mask .active-icon {
  font-size: 26px;
  color: #fff;
  line-height: 122px;
  text-align: center;
}
.image-list .image-list-body .image-list-box .active .active-mask {
  display: block;
}
.image-list .image-list-body .image-list-box .image-item:hover {
  border: 1px solid #16bce2;
}
.image-list .image-list-body .image-footer {
  padding: 8px;
}
.image-list .image-list-body .image-footer .footer-operate .footer-desc {
  color: #999999;
}
.image-list .image-list-body .image-footer .image-pagination {
  text-align: right;
}
